@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&family=Teko&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Teko&display=swap');*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden; /* temporary fix, there is an oversized div*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
    /*--bg:#242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1;
    --nav-height: 10vh;
    --border: 1px solid 474a4d;
    --border-radius: 8px;
    --speed: 500px;
        */
}

html {
    scroll-behavior: smooth;
    margin:0;
    padding:0;

}

.page-container {
    padding: 0;
    margin: 0;
}


ul {
    list-style: none;
    margin:0;
    padding:0;
}


h1 {
    color: #111111;
    font-family:'Teko', sans-serif;
}

h2 {
    color: #242526;
    font-family: 'Teko', sans-serif;
    font-size:40px;
}

h3 {
    font-family: 'Noto Sans', sans-serif;
    font-style:italic;
    
}

p {
    font-family: 'Noto Sans', sans-serif;
    font-size: calc(10px + 0.3vw);
}

a {
    font-family: 'Noto Sans', sans-serif;
}

.navbar {
    height: 10vh;
    background-color: #242526;
    padding: 0;
    border-bottom: 1px solid #474a4d;
    margin:0;
}

.navbar-nav {
    max-width: 100%;
    height: 100%;
    display:grid;
    grid-template-columns: 60% 40%;
    align-items:center;
    padding-left:2vw;
}

.nav-item {
    /* Eye at width*/
    width: calc(var(--nav-size)*0.8);
}

.nav-item-link {
    /*--button-size: calc(var(--nav-height)*0.5);*/
    /* Likley change width and height*/
    transition: -webkit-filter 300ms;
    transition: filter 300ms;
    transition: filter 300ms, -webkit-filter 300ms;
    transition: color 0.2s ease-in;
    font-size: calc(8px + 0.4vw);
}

.nav-item-title {
    font-family: 'Noto Sans', sans-serif;
    font-size: calc(14px + 0.5vw);
    color: floralwhite;

}



.nav-item-link:hover {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    color: mediumaquamarine;
}

.nav-link-container {
    display:grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-items:center;
}


.page-content {
    margin:0;
    padding:0;
}


.main-content {
    display: grid;
    grid-template-columns: 11% 74% 11%;
    padding:0;
    margin:0;
    width:100vw;

}


#home-content {
    display: grid;
    height:90vh;
}

#middle-content {
    display:grid;
}

.header-text {
    font-family: 'Teko', sans-serif;
    font-size:13vw;
    margin:0;
    padding:0;
}


.intro-para{
    font-size:calc(11px + 0.3vw);
}

.header-grid {
    display:grid;
    grid-template-rows:30% 60% 10%;
    
}

.header-title-container {
    justify-content:left;
    max-height:600px;
    
}

.header-body-container {
    justify-content:left;
    display:grid;
    grid-template-columns:10% 60% 10%;
    max-width:600px;
    justify-self:center;
    padding-top:0px;
}


.negative-left {
    
}

.negative-right {
    margin:0;
    padding:0;
}

.triple-row-grid {
    
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    justify-items:center;
    font-size:calc(10px + 0.5vw);
}

.triple-row-item {
    justify-self: center;
    justify-content: center;
    font-size: 95%;
}

.viewport-entry{
    
    display:grid;
}

a {
    color:slategrey;
    text-decoration: none;
    transition: color 0.2s ease-in
}

a:hover{
    color:mediumaquamarine;
}

.projects-container {
    height:85vh;
    display:grid;
    /*grid-template-rows: 10% 90%;*/
}

.projects-highlighted-skill {
    color:mediumaquamarine;
}


.project-info-container {
    display:grid;
}

.project-content {
    display:grid;
    grid-template-rows:40% 40%;
    width:40vw;
}

.project-date {
    font-size:14px;
}

.project-img-container{
    position:relative;
}

.proj-img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
    left: 0;
    bottom: 0;
    position:absolute;
}

.proj-section-header {
    font-family: 'Teko', sans-serif;
    overflow:hidden;
}

.proj-text-container {
    
}


.school-icon {
    width:300px;
    height:300px;
    border-radius:50%;
    
}

.school-info-container{
    justify-items:center;
    display:grid;
    padding-bottom:250px;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  /*color: #61dafb;*/
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

